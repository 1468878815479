import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import IssueStamp from "views/dashboard-admin/Stamp/IssueStamp";

const DashboardAdminManament = Loadable(
  lazy(() => import("views/dashboard-admin/ProjectManament"))
);
const DashboardAdminProjectWaiting = Loadable(
  lazy(() => import("views/dashboard-admin/ProjectWaiting"))
);
const DashboardAdminStamp = Loadable(
  lazy(() => import("views/dashboard-admin/Stamp"))
);

const Details = Loadable(
  lazy(() => import("views/dashboard-admin/ProjectManament/details"))
);
const DetailWaiting = Loadable(
  lazy(() => import("views/dashboard-admin/ProjectWaiting/Detail"))
);

const DetailsStamp = Loadable(
  lazy(() => import("views/dashboard-admin/Stamp/detail/StampDetail"))
);

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/application/detail",
      search: "?_id=id&projectType=projectType",
      element: <DetailWaiting/>,
    },
    {
      path: "/project/detail/:id",
      search: "?projectType=:projectType",
      element: <Details/>,
    },
    {
      path: "/manage-project",
      element: <DashboardAdminManament />,
    },
    {
      path: "/manage-application",
      element: <DashboardAdminProjectWaiting />,
    },
    {
      path: "/admin/project-stamp",
      element: <DashboardAdminStamp />,
    },
    {
      path: "/admin/project-stamp/:id",
      search: "?projectType=:projectType",
      element: <DetailsStamp />,
    },
    {
      path: "/admin/project-stamp/issue",
      element: <IssueStamp />,
    },
  ],
};

export default MainRoutes;
